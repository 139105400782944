<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p">
      <!--  -->
    </v-card>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <FilterBox
      :open.sync="isFilterOpen"
      v-model="filter"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-spacer></v-spacer>
        <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

        <SizeBox width="14" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          :labels="labels"
          sheet-name="sheetName"
          :exclude="['actions']"
        />
      </v-card-text>
      <DataTable
        :list="result.list"
        :total="result.total"
        :fields="headers"
        :labels="labels"
        :replace="['actions', 'platform_order_no', 'created_at']"
        @get-list="fetchData"
      >
        <template #item.actions="{ item }">
          <div class="flex-center">
            <v-btn
              rounded
              v-for="(action, i) in item.actions"
              @click="
                action.action && handleAction((vm) => action.action(vm, item))
              "
              :key="`action: ${item.id}-${i}`"
              :disabled="action.disabled"
              color="Secondary100"
              class="mx-2 my-3"
              depressed
              small
            >
              <v-icon v-if="!!action.icon" size="18" class="mr-1">
                {{ action.icon }}
              </v-icon>
              {{ action.label }}
            </v-btn>
          </div>
        </template>

        <template #item.platform_order_no="{ item }">
          <div>
            <div class="text-no-wrap">{{ item.platform_order_no || '-' }}</div>
            <div class="text-no-wrap">{{ item.merchant_order_no || '-' }}</div>
          </div>
        </template>
        <template #item.created_at="{ item }">
          <div>
            <div class="text-no-wrap">{{ item.created_at | formatTime }}</div>
            <div v-if="item.succeeded_at" class="text-no-wrap">
              {{ item.succeeded_at | formatTime }}
            </div>
            <div v-else class="text-no-wrap error--text">未完成</div>
          </div>
        </template>
      </DataTable>
    </v-card>

    <DetailForm
      :value="showingForm === 'Detail'"
      @close="showingForm = null"
      :passData="passData"
    />
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import DetailForm from '@/components/MerchantPages/order/collect/DetailFrom.vue';

import FilterBox from '@/components/Globals/FilterBox.vue';
import { getCollectChannelTypes } from '@/api/collectChannels';
import { getCollectOrderList } from '@/api/merchantCenter/collectOrders';

import orderStatus from '@/commons/order/status';
import clone from 'just-clone';

const labels = {
  status: clone(orderStatus),
  code: [
    {
      text: '銀行轉帳',
      value: 'bank_transfer',
      color: 'Primary100'
    }
  ]
};

const actions = [
  {
    label: '詳細',
    icon: 'mdi-filter-variant',
    action: (vm, v) => {
      vm.openForm('Detail', v);
    },
    disabled: false
  }
];

export default {
  components: {
    FilterBox,
    DetailForm
  },
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      labels,
      headers: [
        { text: '操作', value: 'actions', align: 'center' },
        {
          text: '平台單號\n/\n商戶單號',
          value: 'platform_order_no',
          align: 'center',
          join: 'merchant_order_no'
        },
        { text: '商戶 ID', value: 'merchant.serial', align: 'center' },
        { text: '訂單狀態', value: 'status', align: 'center' },
        { text: '訂單金額', value: 'amt', align: 'center' },
        // {
        //   text: '實付金額',
        //   value: 'credit_amt',
        //   align: 'center'
        // },
        { text: '手續費', value: 'fee', align: 'center' },
        {
          text: '用戶入賬',
          value: 'credit_amt',
          align: 'center'
        },
        {
          text: '建立時間\n/\n成功時間',
          value: 'created_at',
          dataFormat: this.$root.$options.filters.formatTime,
          align: 'center',
          join: 'succeeded_at'
        },
        {
          text: '銀行名稱',
          value: 'card.bank_name',
          align: 'center'
        },
        {
          text: '帳戶代碼（尾數）',
          value: 'bank_last_digits',
          align: 'center'
        },
        {
          text: '持卡人',
          value: 'bank_holder',
          align: 'center'
        },
        {
          text: '支付通道',
          value: 'code',
          align: 'center'
        }
      ],
      filterOptions: [
        {
          label: '平台單號',
          type: 'text',
          name: 'filter.platfrom_order_no'
        },
        {
          label: '商戶單號',
          type: 'text',
          name: 'filter.merchant_order_no'
        },
        {
          label: '商戶 ID',
          type: 'text',
          name: 'filter.merchant_serial'
        },
        {
          label: '收款帳號',
          type: 'text',
          name: 'filter.banck_card_no'
        },
        {
          label: '通道',
          type: 'autocomplete',
          name: 'filter.collect_channel_id',
          api: getCollectChannelTypes
        },
        {
          label: '是否補單',
          type: 'select',
          name: 'filter.is_replenishment',
          items: [
            { text: '是', value: true },
            { text: '否', value: false }
          ]
        },
        {
          label: '建立時間',
          type: 'timeSet',
          name: 'filter.created_at',
          range: true,
          withTime: true
        },
        {
          label: '成功時間',
          type: 'timeSet',
          name: 'filter.succeeded_at',
          range: true,
          withTime: true
        }
      ]
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      const Orders = await getCollectOrderList({
        ...this.filter,
        ...condition
      });
      if (!Orders.error) {
        this.result.total = Orders.total;
        this.result.page = Orders.page;
        this.$set(
          this.result,
          'list',
          Orders.items.map((item) => ({
            ...item,
            card: {
              ...item.card,
              name_1: item.card.name_1 ? item.card.name_1.split('-')[0] : ''
            },
            actions
          }))
        );
      }

      this.isLoading = false;
    },
    openForm(name, data = {}, mode = '') {
      this.showingForm = name;

      this.$set(this, 'passData', {
        mode,
        ...data
      });
    },
    handleAction(fn) {
      fn(this);
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
